import { Button } from 'antd'
import React, { useEffect, useState } from 'react'
import './header.scss'
import { useLocation, useNavigate } from 'react-router-dom'
import SiteHeader from '../siteHeader/SiteHeader'
const Header = (props) => {
  const [menuFlag, setMenuFlag] = useState(false)
  const [activeHead, setActiveHead] = useState('home')
  const location = useLocation()
  const navigate = useNavigate()
  const hamburgerClick = ()=>{
  setMenuFlag(!menuFlag)
  }
  const navList = [{label:'Home',key:'home'}, {label:'Products',key:'product'}, {label:'Use Cases', key:'usecase'},{label:'How it Works',key:'how-it-works'} ]

  const navbarHandler =(type)=>{

    setActiveHead(type) 
    if(location.pathname != '/'){
      setMenuFlag(false)
      navigate('/')
      window.scrollTo({ top: 0, behavior: 'smooth' });
      
        }  
      else {switch (type) {
        case 'home': {
         
            window.scrollTo({ top: 0, behavior: 'smooth' });
    
        }
          break;
          case 'product':{
            const target = document.getElementById('productSuite');
            let position = target?.getBoundingClientRect();
            window.scrollTo({top: position?.top + window.scrollY - 115, behavior:'smooth'});
          }
            break;
            case 'usecase':{
              const target = document.getElementById('useCase');
              let position = target?.getBoundingClientRect();
              window.scrollTo({top: position?.top + window.scrollY - 115, behavior:'smooth'});
            }
              break;
            case 'how-it-works':{
              const target = document.getElementById('howItWorks');
              let position = target?.getBoundingClientRect();
              window.scrollTo({top: position?.top + window.scrollY - 115, behavior:'smooth'});
            }
              break;
            default:{
              window.scrollTo({ top: 0, behavior: 'smooth' });
              setActiveHead('home')
            }
          break;
      }
      }
   
  }
  const handleBooking =()=>{
    props.setBookingFlag(true)
    setMenuFlag(false)
  }
  useEffect(()=>{
if(['policy', 'terms-and-condition', 'contactus'].includes(location.pathname.split('/')[1])){
  setActiveHead('')
}
else if(!location?.pathname?.includes(activeHead)){
  setTimeout(()=>{
    navbarHandler(location?.pathname?.split('/')[1] !='' ?location.pathname.split('/')[1]: activeHead)
    clearTimeout()
  },600)
}
  },[location])
  return (
    <div className="nav-wrapper">
      <SiteHeader />
    <nav className="navbar">
      <img src="images/mainLogo.svg" alt="Company Logo" 
      onClick={()=> {navbarHandler('home')
        // window.scrollTo({ top: 0, behavior: 'smooth' })
        // setActiveHead("Home")
        }} />
      <div className='mob_btn_container'>
      {!location.pathname.toLowerCase().includes('contact') && <Button className='ml-2 signin_btn signin_btn_mob' onClick={()=>handleBooking()}>Talk to an Expert</Button>}
      <div className={menuFlag ? "menu-toggle is-active" :"menu-toggle"} id="mobile-menu" onClick={()=> hamburgerClick()}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div> 
      </div>
     
      <ul className={menuFlag ? "nav no-search mobile-nav" : "nav no-search"} >
        {navList.map((elm,ind)=>{
          return <li key={elm.key} className={activeHead === elm.key ? "nav-item active-nav":"nav-item"} onClick={()=> navbarHandler(elm.key)}>{elm.label}</li>
        })}
       
       
        {/* <li className="nav-item">Contact Us</li> */}
       {!location.pathname.toLowerCase().includes('contact') && <Button className='ml-2 signin_btn' onClick={()=>handleBooking()}>Talk to an Expert</Button>}
      </ul>
      
    </nav>
    </div>
  )
}

export default Header