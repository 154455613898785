// import './App.css';
import Header from './components/header/header';
import "./assets/scss/style.scss";
import { useState } from 'react';
import BookingForm from './components/bookingForm/bookingForm';
import { Route, Routes } from 'react-router-dom';
import Layout from './layout';
function App() {
  const [bookingFlag, setBookingFlag] = useState(false)
  return (
    <div className="App">
      {window.location.pathname === '/vpi' ? '' :<div className="nav_box">
				<Header bookingFlag={bookingFlag} setBookingFlag ={setBookingFlag} />
			</div>}
      <Routes>
        <Route path='/' exact={true} element={<Layout bookingFlag={bookingFlag} setBookingFlag={setBookingFlag} />} />
        <Route path='/contactus' exact={true} element={<BookingForm bookingFlag={bookingFlag} setBookingFlag={setBookingFlag} />} />
        <Route path='*' element={<Layout bookingFlag={bookingFlag} setBookingFlag={setBookingFlag} />} />
      </Routes>
    </div>
  );
}

export default App;
