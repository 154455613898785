import React from "react";
import mainLogo from "../../../assets/images/flixstock-logo.svg";
import arrowIcon from "../../../assets/images/FirstFold/arrow-icon.svg";
import { Button } from "antd";
import "./style.scss";
import { Link } from "react-router-dom";

const FirstFold_v1 = (props) => {
  const handleBooking = () => {
    props.setBookingFlag(true);
  };
  return (
    <div className="first_fold_v1">
      <div className="logo">
        <img src={mainLogo} alt="" />
      </div>
      <div className="video_section">
        <h2 className="title">Vendor-provided images</h2>
        <p className="sub_text">
        The Problem: vendor-provided images (VPI) come in all styles and sizes that do not match with your brand's style guide. This makes your site appear cluttered and provides an inconsistent visual identity for your visitors.
        </p>
        <p className="sub_text">
        The solution: FlixStock's suite of tools helps transform images from different sources according to your brand's style guide. Such transformed imagery blends well with the rest of the images on your website, making the site imagery visually consistent and helping improve Customer experience.
        </p>
        <div className="button_section">
          <button onClick={() => handleBooking()}>
            GET IN TOUCH
            <img src={arrowIcon} alt="" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default FirstFold_v1;
